$( function() {

	// Слайдер 
	$( '.review' ).slick({
		arrows: true,
		infinite: false,
		initialSlide: 1,
		draggable: false,
		dots: true,
		dotsClass: "my-dots",
		prevArrow: $('.prev'), 
	    nextArrow: $('.next')
	});

	// Маска для ввода номера телефона
	$('input[name=telephone]').mask("+7 (999) 999-99-99");

	// Прокрутка
	$("a[href='#advantages']").click(function() {
		var divPosition = $('#advantages').offset();
		$('html, body').animate({scrollTop: divPosition.top}, "slow");
		return false;
	});

	$("a[href='#consult']").click(function() {
		var divPosition = $('#consult').offset();
		$('html, body').animate({scrollTop: divPosition.top}, "slow");
		return false;
	});

	$("a[href='#are_you_ready']").click(function() {
		var divPosition = $('#are_you_ready').offset();
		$('html, body').animate({scrollTop: divPosition.top}, "slow");
		return false;
	});

	// PageUp
	$(window).scroll(function() {
		if ($(this).scrollTop() > 1600) {
			$('.pageup').fadeIn('slow');
		}
		else {
			$('.pageup').fadeOut('slow');
		}
	});

	$("a[href='#up']").click(function() {
		var divPosition = $('#up').offset();
		$('html, body').animate({scrollTop: divPosition.top}, "slow");
		return false;
	});


	// Анимация появления
	$(window).scroll(function() {
		$('.adv__item').each(function() {
			var imagePos = $(this).offset().top;
			var topOfWindow = $(window).scrollTop();
			if (imagePos < topOfWindow + 600) {
				$(this).addClass('animated');
				$(this).addClass('fadeInLeft');
			}
		});
	});


	// Модальные окна
	$('#icon-consult').on('click', function(){
		$('.overlay, #consultation').fadeIn(500);
	});

	$('.overlay__close').on('click', function() {
		$('.overlay, #consultation, #thanks').fadeOut('slow');
	});

	// Меню
	// $('#menu-bar').on('click', function() {
	// 	if ($('svg').hasClass('active')) {
	// 		$('#menu-header').addClass('fadeInRight');
	// 		$('#menu-header').removeClass('fadeOutRight');
	// 		$('.overlay').fadeIn(500);
	// 	}
	// 	else {
	// 		$('#menu-header').addClass('fadeOutRight');
	// 		$('#menu-header').removeClass('fadeInRight');
	// 		$('.overlay').fadeOut(500);
	// 	}
	// });

	// Валидация и отправка форм
	sendValidateForm('#consult form');
	sendValidateForm('#consultation form');


	function sendValidateForm(form) {
		$(form).validate({
			rules: {
				name: {
					required: true,
					minlength: 2	
				},
				telephone: "required",
				email: {
					required: true,
					email: true
				}
			},
			messages: {
				name: "Пожалуйста, введите свое имя",
				telephone: "Пожалуйста, введите свой номер телефона",
				email: {
				  required: "Пожалуйста, введите свою эл. почту",
				  email: "Неправильно введен почтовый ящик"
				}
			},
		
			submitHandler: function() {
				$.ajax({
					type: "POST",
					url: "mailer/smart.php",
					data: $(form).serialize()          
				}).done(function() {
					$(form).find("input").val("");
					$('#consultation').fadeOut();
					$('.overlay, #thanks').fadeIn('slow');
					$('form').trigger('reset');
				});
			}
		});
	}
});